import { faMemo } from "@awesome.me/kit-af809b8b43/icons/classic/solid";
import { Anchor, Stack, Text } from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet-async";
import { Link, generatePath } from "react-router-dom";

import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { TextOverflowWrapper } from "@/components/atoms/TextOverflowWrapper";
import { SoftwareGrid } from "@/components/molecules/SoftwareGrid/SoftwareGrid";
import { TechnicalAreasList } from "@/components/molecules/TechnicalAreasList/TechnicalAreasList";
import { WistiaVideoPlayer } from "@/components/molecules/VideoPlayer/VideoPlayer";
import { ComponentError } from "@/components/organisms/Error/ComponentError";
import { UserProfile } from "@/components/organisms/UserProfile/UserProfile";
import {
  useExpert,
  useExpertDocuments,
  useExpertSoftwareSelection,
} from "@/hooks/use-expert";
import { useExpertByIdentifierTechnicalAreas } from "@/hooks/use-technical-areas";
import { paths } from "@/routes/paths";
import { Amplitude, ExpertProfileEvent } from "@/third-party/amplitude";
import type { SDocument, User } from "types";

type Props = {
  identifier: string;
  className?: string;
  onDocumentClick?: (document: SDocument) => void;
};

export function ExpertProfile(props: Props) {
  const expert = useExpert({ identifier: props.identifier });

  const identifier = expert.data?.urlId ?? expert.data?.id;

  const softwares = useExpertSoftwareSelection({
    identifier,
  });
  const technicalAreas = useExpertByIdentifierTechnicalAreas({ identifier });
  const documents = useExpertDocuments({
    identifier,
  });

  if (
    expert.isLoading ||
    softwares.isLoading ||
    technicalAreas.isLoading ||
    documents.isLoading
  ) {
    return <Skeleton repeat={5} />;
  }

  if (
    expert.isError ||
    softwares.isError ||
    technicalAreas.isError ||
    documents.isError
  ) {
    return <ComponentError />;
  }

  return (
    <Stack className={props.className}>
      <UserProfile user={expert?.data || {}} showFullProfileOnClick={false} />
      {expert.data && (
        <>
          <MetaTags expert={expert.data} />
          <Text className="mt-2 font-semibold text-xl/normal">
            Expertise summary
          </Text>
          <TextOverflowWrapper>{expert?.data?.summary}</TextOverflowWrapper>
          {expert.data?.profileVideoUrl && (
            <>
              <Text className="mt-2 font-semibold text-xl/normal">
                Video introduction
              </Text>
              <WistiaVideoPlayer
                errorMsg="We are unable to find this video."
                onStart={() => {
                  Amplitude.track(
                    ExpertProfileEvent.name,
                    ExpertProfileEvent.properties.clickVideo,
                  );
                }}
                url={expert.data.profileVideoUrl}
              />
            </>
          )}
          <Text className="mt-2 font-semibold text-xl/normal">
            Software expertise
          </Text>
          {softwares?.data?.length ? (
            <SoftwareGrid softwares={softwares.data} />
          ) : (
            <div className="rounded-lg bg-[--mantine-color-gray-0] p-6">
              <Text className="text-[--mantine-color-gray-6]">
                This expert hasn't yet provided their areas of software
                expertise. Please check their LinkedIn profile to learn about
                their experience.
              </Text>
            </div>
          )}
          <Text className="mt-2 font-semibold text-xl/normal">
            Technical expertise
          </Text>
          {technicalAreas.data?.length ? (
            <TechnicalAreasList
              items={technicalAreas.data}
              defaultExpandedItemsCount={6}
            />
          ) : (
            <div className="rounded-lg bg-[--mantine-color-gray-0] p-6">
              <Text className="text-[--mantine-color-gray-6]">
                This expert hasn't added areas of technical expertise yet.
                Please check their LinkedIn profile to learn about their
                experience.
              </Text>
            </div>
          )}
          <Text className="mt-2 font-semibold text-xl/normal">
            Guides written
          </Text>
          {documents.data?.length ? (
            <ul className="m-0 flex list-none flex-col gap-2 p-0">
              {documents?.data?.map((sDocument) => (
                <li key={sDocument.id}>
                  <Anchor
                    component={Link}
                    to={generatePath(paths.documentation.viewer, {
                      urlSlug: sDocument.urlTitle,
                    })}
                    className="flex items-center gap-2 py-2 text-inherit"
                    onClick={() => props.onDocumentClick?.(sDocument)}
                  >
                    {sDocument.docType === "SOFTWARE_CHECKLIST" ? (
                      <>
                        <img
                          src={sDocument.software?.icon}
                          alt={sDocument.software?.name}
                          className="size-5"
                        />
                        <span className="cursor-pointer font-medium underline">
                          {sDocument.software?.name} guide
                        </span>
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon
                          icon={faMemo}
                          className="size-5 text-[--mantine-primary-color-filled]"
                        />
                        <span className="cursor-pointer font-medium underline">
                          {sDocument.topic?.title}
                        </span>
                      </>
                    )}
                  </Anchor>
                </li>
              ))}
            </ul>
          ) : (
            <div className="rounded-lg bg-[--mantine-color-gray-0] p-6">
              <Text className="text-[--mantine-color-gray-6]">
                This expert hasn't yet created any documents.
              </Text>
            </div>
          )}
        </>
      )}
    </Stack>
  );
}

function MetaTags({ expert }: { expert: User }) {
  const path = generatePath(paths.experts.profile, { identifier: expert.id });

  // TODO: When upgrading to >=react-router 6.4, replace with useBasename
  const fullUrl = `${window.location.protocol}//${window.location.host}${path}`;

  return (
    <Helmet prioritizeSeoTags={true}>
      <meta property="og:url" content={fullUrl} />
      <link rel="canonical" href={fullUrl} />
      <title>GuideStack Expert - {expert.name}</title>
      <meta name="description" content={expert.summary} />
    </Helmet>
  );
}
