import { useQuery } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";

import { api } from "@/api/client";
import { SetUser } from "@/third-party/amplitude";
import { useFeatureFlag } from "@/third-party/amplitude-experiments";
import { getAccessToken, setupAxios401Interceptor } from "@/utils/auth";
import { randomProfileColorPicker } from "@/utils/profile-colors";
import type { User } from "types";

type UserRole = "admin" | "customer";

type AuthContextProps = {
  user: User | null | undefined;
  isLoadingUser: boolean;
  role: UserRole;
  setRole: Dispatch<SetStateAction<UserRole>>;
  setIsSignedIn: Dispatch<SetStateAction<boolean>>;
};

export const CURRENT_USER_QUERYKEY = ["currentUser"];

function useUser({ enabled }: { enabled: boolean }) {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: CURRENT_USER_QUERYKEY,
    structuralSharing: false,
    queryFn: async () => {
      const { data } = await axios.get<User>("/users/me");
      data.randomProfileColor = randomProfileColorPicker(data.email);

      setupAxios401Interceptor(queryClient);
      return data;
    },
    enabled,
    staleTime: Number.POSITIVE_INFINITY,
  });
}

const AuthContext = createContext<AuthContextProps | null>(null);

const AuthProvider = (props: PropsWithChildren) => {
  const accessToken = getAccessToken();
  const [isSignedIn, setIsSignedIn] = useState(!!accessToken);
  const { isOn: isExpertFirstOn } = useFeatureFlag(
    "top-nav-expert-directory-first",
  );

  // Set the Authorization header for dev environment
  if (import.meta.env.DEV && isSignedIn) {
    // TODO: Remove this after direct usage of axios is cleaned up
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    api.client.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  const {
    data: user,
    isLoading: isLoadingUser,
    isError,
  } = useUser({ enabled: isSignedIn });

  const [role, setRole] = useState<"admin" | "customer">("customer");

  if (user) {
    SetUser(user, {
      dashboard_tab_test: isExpertFirstOn
        ? "Experts First"
        : "Documentation First",
    });
  }

  useEffect(() => {
    setRole(user?.isAdmin ? "admin" : "customer");
  }, [user?.isAdmin]);

  useEffect(() => {
    if (isError) {
      setIsSignedIn(false);
    }
  }, [isError]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoadingUser,
        role,
        setRole,
        setIsSignedIn,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
