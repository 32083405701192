import { type ReactNode, Suspense, lazy, useEffect } from "react";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";

// Containers
import { Authenticated } from "@/screens/authenticated/Authenticated";

// screens
import { Auth } from "@/screens/auth/Auth";
import { EditDocument } from "@/screens/edit-document/EditDocument";
import { NotFound } from "@/screens/not-found/NotFound";
import { NotificationsView } from "@/screens/notifications/Notifications";
import { ClientOnboarding } from "@/screens/onboarding/ClientOnboarding";
import { ExpertOnboarding } from "@/screens/onboarding/ExpertOnboarding";
import { PostSSOAgreement } from "@/screens/post-sso-agreement/PostSSOAgreement";
import { Profile } from "@/screens/profile/Profile";
import { UserTypeSelection } from "@/screens/user-type-selection/UserTypeSelection";

import { Skeleton } from "@/components/atoms/Loading/Skeleton";
// sub-screens/components of screens
import { SignIn } from "@/components/organisms/SignIn/SignIn";
import DummySessions from "@/screens/sessions/DummySessions";
import { Unauthenticated } from "@/screens/unauthenticated/Unauthenticated";
import { Amplitude, LandingPageCTAEvent } from "@/third-party/amplitude";
import { useFeatureFlag } from "@/third-party/amplitude-experiments";
import { paths } from "./paths";

const DocumentViewScreen = lazy(
  () => import("@/screens/documentation/DocumentViewer"),
);
const DocumentDashboardScreen = lazy(
  () => import("@/screens/document-dashboard/DocumentDashboard"),
);
const ExpertListingScreen = lazy(() => import("@/screens/experts/Experts"));
const SessionsScreen = lazy(() => import("@/screens/sessions/Sessions"));
const ExpertScreen = lazy(() => import("@/screens/expert/Expert"));
const AccountScreen = lazy(() => import("@/screens/account/Account"));

export function RemixedRoutes() {
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const utmMedium = searchParams.get("utm_medium");
    const utmContent = searchParams.get("utm_content");
    const utmSource = searchParams.get("utm_source");
    const referrer = document.referrer;

    if (referrer || utmSource) {
      Amplitude.track(LandingPageCTAEvent.name, {
        ...LandingPageCTAEvent.properties.clickCTA,
        page_url: referrer,
        utm_medium: utmMedium,
        utm_content: utmContent,
        utm_source: utmSource,
      });
    }
  }, [searchParams.get]);

  const { isOn: isExpertFirstOn } = useFeatureFlag(
    "top-nav-expert-directory-first",
  );

  return (
    <Routes>
      {/* public routes */}
      <Route
        path={paths.root}
        element={
          <Navigate
            to={isExpertFirstOn ? paths.experts.root : paths.documentation.root}
            replace
          />
        }
      />
      <Route path={paths.signIn} element={<Auth />}>
        <Route index element={<SignIn />} />
      </Route>
      <Route path={paths.authCallback} element={null} />
      <Route element={<Unauthenticated />}>
        <Route
          path={paths.documentation.viewer}
          element={
            <LazyLoadedPage>
              <DocumentViewScreen />
            </LazyLoadedPage>
          }
        />
        <Route
          path={paths.documentation.root}
          element={
            <LazyLoadedPage>
              <DocumentDashboardScreen />
            </LazyLoadedPage>
          }
        />
        <Route
          path={paths.experts.profile}
          element={
            <LazyLoadedPage>
              <ExpertScreen />
            </LazyLoadedPage>
          }
        />
        <Route
          path={paths.experts.root}
          element={
            <LazyLoadedPage>
              <ExpertListingScreen />
            </LazyLoadedPage>
          }
        />
        <Route path={paths.sessions.preview} element={<DummySessions />} />
      </Route>

      {/* protected routes */}
      <Route element={<Authenticated showNavigationBar={false} />}>
        <Route path={paths.usageAgreement} element={<PostSSOAgreement />} />
        <Route path={paths.userTypeSelection} element={<UserTypeSelection />} />
        <Route path={paths.onboarding.client} element={<ClientOnboarding />} />
        <Route path={paths.onboarding.expert} element={<ExpertOnboarding />} />
        <Route
          path={`${paths.profile.documents.edit}/*`}
          element={<EditDocument />}
        />
      </Route>

      <Route element={<Authenticated />}>
        <Route path={`${paths.profile.root}/*`} element={<Profile />} />
        <Route
          path={`${paths.sessions.root}/*`}
          element={
            <LazyLoadedPage>
              <SessionsScreen />
            </LazyLoadedPage>
          }
        />
        <Route
          path={`${paths.account.root}/*`}
          element={
            <LazyLoadedPage>
              <AccountScreen />
            </LazyLoadedPage>
          }
        />
        <Route
          path={paths.notifications}
          element={
            <LazyLoadedPage>
              <NotificationsView />
            </LazyLoadedPage>
          }
        />
      </Route>

      {/* not found and other undefined routes */}
      <Route path={paths.notFound} element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function LazyLoadedPage({ children }: { children: ReactNode }) {
  return <Suspense fallback={<Skeleton repeat={5} />}>{children}</Suspense>;
}
