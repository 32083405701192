import {
  faAddressCard,
  faMagnifyingGlass,
} from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import {
  Button,
  Container,
  Flex,
  Paper,
  Stack,
  Text,
  UnstyledButton,
} from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx/lite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import type { UpdateProfileDto } from "@/api/openapi";
import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { Logo } from "@/components/atoms/Logo/Logo";
import { Screen } from "@/components/atoms/Screen/Screen";
import { useAuth } from "@/hooks/use-auth";
import { useSignUpSearchParams } from "@/hooks/use-page-search-params";
import { useUpdateUserMutation } from "@/hooks/use-user-mutation";
import { paths } from "@/routes/paths";
import { Amplitude, SignUpAndLoginEvent } from "@/third-party/amplitude";

const userTypes = [
  {
    type: "expert",
    icon: faAddressCard,
    title: "I want to provide IT expertise",
    caption:
      "I am an IT manager or service provider looking to offer expertise",
  },
  {
    type: "client",
    icon: faMagnifyingGlass,
    title: "I need IT support",
    caption:
      "I am a small business or startup looking for IT management support",
  },
];

type UserType = (typeof userTypes)[0]["type"] | "";

const UserTypeSelection = () => {
  const { postSignUp } = useSignUpSearchParams();

  const { user } = useAuth();
  const { mutate: updateUser } = useUpdateUserMutation();
  const navigate = useNavigate();

  const [userType, setUserType] = useState<UserType>("");
  const [errorMessage, setErrorMessage] = useState("");

  const isUserTypeKnown = Boolean(user?.expertProfile || user?.clientProfile);

  // When `postSignUp` is available, we assume the user is a client and we skip this screen
  useEffect(() => {
    if (postSignUp && !isUserTypeKnown) {
      updateUser(
        {
          clientProfile: {},
        },
        {
          onSuccess: () => navigate(postSignUp, { replace: true }),
        },
      );
      return;
    }
  }, [postSignUp, updateUser, navigate, isUserTypeKnown]);

  if (postSignUp && !isUserTypeKnown) {
    return (
      <Screen>
        <Container>
          <header>
            <Logo />
          </header>
          <main className="-mx-6 mt-6 mb-0 flex justify-center lg:m-0 lg:mt-28">
            <Paper className="w-full lg:max-w-[46rem]">
              <Skeleton repeat={2} />
            </Paper>
          </main>
        </Container>
      </Screen>
    );
  }

  // Prevent users from accessing this screen if they have already selected a user type
  if (isUserTypeKnown) {
    return null;
  }

  const onConfirm = async () => {
    if (userType === "") {
      setErrorMessage("Please choose an option.");
      return;
    }

    setErrorMessage("");

    try {
      // Explorers will have no profile, and will be nudged
      // to complete their profile later
      const profile: UpdateProfileDto = {
        expertProfile: userType === "expert" ? {} : undefined,
        clientProfile: userType === "client" ? {} : undefined,
      };

      updateUser(profile);

      let redirectPath = paths.root;
      switch (userType) {
        case "expert":
          redirectPath = paths.onboarding.expert;
          break;

        case "client":
          redirectPath = paths.onboarding.client;
          break;
      }

      navigate(redirectPath, { replace: true });
    } catch (error) {
      const errorObject = error as Error;
      setErrorMessage(errorObject.message);
    }
  };

  return (
    <Screen>
      <Container>
        <header>
          <Logo />
        </header>
        <main className="-mx-6 mt-6 mb-0 flex justify-center lg:m-0 lg:mt-28">
          <Paper className="w-full lg:max-w-[46rem]">
            <Stack>
              <Text component="h1" className="font-semibold text-2xl/normal">
                Which of the following describes what you are looking for?
              </Text>
              <Flex className="flex-col lg:flex-row">
                {userTypes.map(({ type, icon, title, caption }) => (
                  <UnstyledButton
                    key={type}
                    className={clsx(
                      "flex w-full cursor-pointer flex-col items-center justify-start gap-4 rounded border border-[--mantine-color-gray-3] border-solid px-5 py-10",
                      userType === type &&
                        "border-[--mantine-primary-color-6] bg-[--mantine-primary-color-0]",
                    )}
                    onClick={() => {
                      setUserType(type);
                      Amplitude.track(
                        SignUpAndLoginEvent.name,
                        type === "expert"
                          ? SignUpAndLoginEvent.properties.selectExpert
                          : SignUpAndLoginEvent.properties.selectClient,
                      );
                    }}
                    aria-label={type}
                  >
                    <FontAwesomeIcon
                      icon={icon}
                      className={clsx(
                        "size-8 text-[--mantine-color-gray-6]",
                        userType === type &&
                          "text-[--mantine-primary-color-filled]",
                      )}
                    />
                    <Text className="text-center font-semibold">{title}</Text>
                    <Text className="text-center text-[--mantine-color-gray-7]">
                      {caption}
                    </Text>
                  </UnstyledButton>
                ))}
              </Flex>
              <Flex
                className={clsx(
                  "flex-col lg:flex-row lg:items-center lg:justify-end",
                  errorMessage && "lg:justify-between",
                )}
              >
                {errorMessage && (
                  <Text className="text-center text-[--mantine-color-error] lg:text-left">
                    {errorMessage}
                  </Text>
                )}
                <Button onClick={onConfirm} className="lg:self-end">
                  Confirm
                </Button>
              </Flex>
            </Stack>
          </Paper>
        </main>
      </Container>
    </Screen>
  );
};

export { UserTypeSelection };
