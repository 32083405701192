import {
  faChartPieSimpleCircleDollar,
  faFileLines,
  faPresentationScreen,
} from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import { Button, Group, Paper, Stack, Text } from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import GoogleLogo from "@/assets/logos/google.png";
import MicrosoftLogo from "@/assets/logos/microsoft.png";
import { useAuth } from "@/hooks/use-auth";
import { useGoogleSSO } from "@/hooks/use-google-sso";
import { useMicrosoftSSO } from "@/hooks/use-microsoft-sso";
import { useSignUpSearchParams } from "@/hooks/use-page-search-params";
import { paths } from "@/routes/paths";
import { Amplitude, SignUpAndLoginEvent } from "@/third-party/amplitude";
import { SSOSignIn } from "@/utils/auth";
import type { SignInType } from "types";

const GOOGLE = "GOOGLE";
const MICROSOFT = "MICROSOFT";

type Initiator = "GOOGLE" | "MICROSOFT" | null;

export function SignIn() {
  const { user } = useAuth();

  if (user) {
    return <Navigate to={paths.root} replace />;
  }

  return (
    <Paper className="w-full max-w-[30rem]">
      <SignInOptions />
    </Paper>
  );
}

export function SignInOptions() {
  const { setIsSignedIn } = useAuth();
  const [initiator, setInitiator] = useState<Initiator>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const { closeSignUpModal } = useSignUpSearchParams();

  const { initiateSSO: initiateGoogleSSO } = useGoogleSSO();
  const { initiateSSO: initiateMicrosoftSSO } = useMicrosoftSSO();

  useEffect(() => {
    let signInType: SignInType;
    let initiateSSO: typeof initiateGoogleSSO | typeof initiateMicrosoftSSO;

    switch (initiator) {
      case GOOGLE:
        signInType = "GOOGLE_SSO";
        initiateSSO = initiateGoogleSSO;
        break;

      case MICROSOFT:
        signInType = "MICROSOFT_SSO";
        initiateSSO = initiateMicrosoftSSO;
        break;

      default:
        return;
    }

    initiateSSO({
      onSuccess: async (response) => {
        try {
          await SSOSignIn({
            accessToken: response.accessToken,
            token: "idToken" in response ? response.idToken : undefined,
            signInType,
          });
          setIsSignedIn(true);
          setInitiator(null);
        } catch (error) {
          const errorObj = error as Error;
          setErrorMessage(errorObj.message);
          setInitiator(null);
        } finally {
          closeSignUpModal({
            deletePostSignUp: false,
          });
        }
      },
      onCancel() {
        setInitiator(null);
      },
      onDismiss() {
        setInitiator(null);
      },
    });
  }, [
    initiator,
    initiateGoogleSSO,
    initiateMicrosoftSSO,
    setIsSignedIn,
    closeSignUpModal,
  ]);

  return (
    <Stack className="gap-6">
      <Text component="h1" className="font-semibold text-2xl/normal">
        Continue with GuideStack
      </Text>
      <Button
        variant="outline"
        leftSection={
          <img src={GoogleLogo} alt="Google logo" className="size-6" />
        }
        onClick={() => {
          setInitiator(GOOGLE);
          Amplitude.track(
            SignUpAndLoginEvent.name,
            SignUpAndLoginEvent.properties.selectGoogle,
          );
        }}
        loading={initiator === GOOGLE}
        disabled={initiator === MICROSOFT}
      >
        Continue with Google
      </Button>
      <Button
        variant="outline"
        leftSection={
          <img src={MicrosoftLogo} alt="Microsoft logo" className="size-6" />
        }
        onClick={() => {
          setInitiator(MICROSOFT);
          Amplitude.track(
            SignUpAndLoginEvent.name,
            SignUpAndLoginEvent.properties.selectMicrosoft,
          );
        }}
        loading={initiator === MICROSOFT}
        disabled={initiator === GOOGLE}
      >
        Continue with Microsoft
      </Button>
      {errorMessage && (
        <Text className="text-center text-[--mantine-color-error]">
          {errorMessage}
        </Text>
      )}
      <Stack component="ul" className="m-0 list-none flex-col p-0">
        <Group component="li" className="flex-nowrap items-start">
          <FontAwesomeIcon
            icon={faPresentationScreen}
            title="presentation screen icon"
            className="size-6 shrink-0 text-[--mantine-primary-color-filled]"
          />
          <Text className="text-[--mantine-color-gray-7]">
            Book one-on-one advisory sessions directly with verified IT experts
          </Text>
        </Group>
        <Group component="li" className="flex-nowrap items-start">
          <FontAwesomeIcon
            icon={faFileLines}
            title="file text icon"
            className="size-6 shrink-0 text-[--mantine-primary-color-filled]"
          />
          <Text className="text-[--mantine-color-gray-7]">
            Access simple, expert-written IT guides on software and best
            practices
          </Text>
        </Group>
        <Group component="li" className="flex-nowrap items-start">
          <FontAwesomeIcon
            icon={faChartPieSimpleCircleDollar}
            title="pie chart icon"
            className="size-6 shrink-0 text-[--mantine-primary-color-filled]"
          />
          <Text className="text-[--mantine-color-gray-7]">
            Contribute to the GuideStack knowledge base to become a platform
            expert
          </Text>
        </Group>
      </Stack>
    </Stack>
  );
}
