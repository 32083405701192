import "@flpstudio/design-system/global.css";
import { Notifications } from "@flpstudio/design-system";
import {
  ErrorBoundary,
  Profiler,
  browserTracingIntegration,
  captureException,
  captureMessage,
  init,
  reactRouterV6BrowserTracingIntegration,
} from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import { StrictMode, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { ampli } from "@/ampli";
import { AuthProvider } from "./providers/AuthProvider";
import { RemixedRoutes } from "./routes/router";
import { ThemeProvider, theme } from "./styles/theming";
import "./App.css";
import { showNotification } from "@/utils/notification";
import { FullPageError } from "./components/organisms/Error/FullPageError";

axios.defaults.baseURL = `${import.meta.env.VITE_API_BASE_URL}/v1`;
axios.defaults.withCredentials = true;

init({
  enabled: import.meta.env.MODE !== "development",
  environment: import.meta.env.MODE,
  dsn: "https://4d4b0142277967832198a3dbcb77b45c@o4507610489421824.ingest.us.sentry.io/4507581522575360",
  integrations: [
    browserTracingIntegration(),
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
  // TODO: Add production target
  tracePropagationTargets:
    (import.meta.env.MODE === "staging" && [
      /^https:\/\/api\.streamline\.dev\//,
    ]) ||
    [],
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
    mutations: {
      onError: () => {
        showNotification({
          title: "Sorry we couldn't complete your request",
          message: "Please try again in a moment.",
          color: "error",
        });
      },
    },
  },
});

const App = () => {
  useEffect(() => {
    if (window) {
      try {
        // https://vitejs.dev/guide/build.html#load-error-handling
        const reloadedKey = "reloadForPreLoadErr";
        window.sessionStorage.removeItem(reloadedKey);

        const preloadErrorHandler = (event: Event) => {
          event.preventDefault();
          if (!window.sessionStorage.getItem(reloadedKey)) {
            window.location.reload();
            window.sessionStorage.setItem(reloadedKey, "reloaded");
            captureMessage("preloadError handler triggered");
          } else {
            captureMessage(
              "preloadError handler triggered but chunk still failed to load.",
            );
          }
        };

        window.addEventListener("vite:preloadError", preloadErrorHandler);

        return () => {
          window.removeEventListener("vite:preloadError", preloadErrorHandler);
        };
      } catch (error) {
        captureException(error);
      }
    }
  }, []);

  useEffect(() => {
    if (!ampli.isLoaded) {
      ampli.load({
        environment: "guidestackproduction",
        client: {
          apiKey: import.meta.env.VITE_AMPLITUDE_API_KEY,
          configuration: { defaultTracking: false },
        },
      });
    }

    if (import.meta.env.MODE === "staging" || import.meta.env.DEV) {
      // biome-ignore lint/suspicious/noExplicitAny: `env` is a custom property that is not defined in the Ampli SDK
      ampli.identify(undefined, { env: "guidestack" } as any);
    }
  }, []);

  return (
    <StrictMode>
      <HelmetProvider>
        <Profiler>
          <ErrorBoundary fallback={<FullPageError />}>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <ThemeProvider theme={theme}>
                  <Helmet>
                    <title>GuideStack</title>
                    <meta property="og:url" content={window.location.href} />
                  </Helmet>
                  <Notifications />
                  <BrowserRouter future={{ v7_startTransition: true }}>
                    <RemixedRoutes />
                  </BrowserRouter>
                </ThemeProvider>
              </AuthProvider>
            </QueryClientProvider>
          </ErrorBoundary>
        </Profiler>
      </HelmetProvider>
    </StrictMode>
  );
};

const appRoot = document.getElementById("streamline-app") as HTMLDivElement;

export { App, appRoot };
