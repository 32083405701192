import { Anchor, Button, Text, TextInput } from "@flpstudio/design-system";
import { zodResolver } from "@hookform/resolvers/zod";
import { type SubmitHandler, useForm } from "react-hook-form";
import { z as validator } from "zod";

import { WistiaVideoPlayer } from "@/components/molecules/VideoPlayer/VideoPlayer";
import { useAuth } from "@/hooks/use-auth";
import { useUpdateUserMutation } from "@/hooks/use-user-mutation";
import { paths } from "@/routes/paths";
import { Amplitude, ExpertOwnProfileEvent } from "@/third-party/amplitude";

const schema = validator.object({
  profileVideoUrl: validator
    .string()
    .trim()
    .regex(
      /^(https?:\/\/)?.*wistia\.com\/medias\/.+/i,
      "Please paste a video link from Wistia",
    )
    .optional()
    .or(validator.literal("")),
});

type FormSchema = validator.infer<typeof schema>;

export function ExpertIntroVideoForm({ onClose }: { onClose: () => void }) {
  const { user } = useAuth();
  const { mutate: updateUser } = useUpdateUserMutation();
  const { formState, getValues, register, handleSubmit, watch } =
    useForm<FormSchema>({
      resolver: zodResolver(schema),
      mode: "onChange",
      reValidateMode: "onBlur",
      defaultValues: {
        profileVideoUrl: user?.expertProfile?.profileVideoUrl ?? "",
      },
    });

  const previewLink = watch("profileVideoUrl");

  const submitHandler: SubmitHandler<FormSchema> = () => {
    updateUser({
      ...user,
      clientProfile: undefined,
      expertProfile: {
        ...user?.expertProfile,
        profileVideoUrl: getValues("profileVideoUrl"),
      },
    });

    let trackingAction = ExpertOwnProfileEvent.properties.addVideo;
    if (user?.expertProfile?.profileVideoUrl) {
      const newVideoLink = getValues("profileVideoUrl");
      if (!newVideoLink) {
        trackingAction = ExpertOwnProfileEvent.properties.removeVideo;
      } else if (newVideoLink !== user?.expertProfile?.profileVideoUrl) {
        trackingAction = ExpertOwnProfileEvent.properties.changeVideo;
      }
    }
    Amplitude.track(ExpertOwnProfileEvent.name, trackingAction);

    onClose();
  };

  return (
    <form
      className="flex flex-col gap-6"
      onSubmit={handleSubmit(submitHandler)}
    >
      <Text>
        Please follow
        <Anchor
          href={paths.external.howToShareWistiaVideo}
          target="_blank"
          rel="noreferrer noopener"
        >
          {" these instructions "}
        </Anchor>
        to get the Wistia URL for your video.
      </Text>
      {previewLink && !formState.errors.profileVideoUrl?.message && (
        <WistiaVideoPlayer
          errorMsg="We are unable to find your video. Please check the link."
          url={previewLink}
        />
      )}

      <TextInput
        label="Wistia video link"
        placeholder="Please paste your Wistia video link"
        autoFocus
        error={formState.errors.profileVideoUrl?.message}
        {...register("profileVideoUrl")}
      />
      <div className="flex flex-row-reverse">
        <Button type="submit">Save</Button>
      </div>
    </form>
  );
}
