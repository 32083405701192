import { Modal } from "@flpstudio/design-system";
import { useEffect, useState } from "react";
import { Navigate, Outlet, createSearchParams } from "react-router-dom";

import { NavigationBar } from "@/components/organisms/NavigationBar/NavigationBar";
import { SignInOptions } from "@/components/organisms/SignIn/SignIn";
import { ViewExpertProfileDialog } from "@/components/organisms/ViewExpertProfileDialog/ViewExpertProfileDialog";
import { useAuth } from "@/hooks/use-auth";
import { useSignUpSearchParams } from "@/hooks/use-page-search-params";
import { paths } from "@/routes/paths";
import { useDisclosure } from "@mantine/hooks";

export function Unauthenticated() {
  const { postSignUp } = useSignUpSearchParams();

  const { user } = useAuth();

  const [redirectTo, setRedirectTo] = useState("");

  useEffect(() => {
    if (user) {
      const areAgreementsAccepted = !!user?.termsAcceptedAt;
      const isUserTypeKnown = user?.expertProfile || user?.clientProfile;

      if (!areAgreementsAccepted) {
        setRedirectTo(paths.usageAgreement);
      } else if (!isUserTypeKnown) {
        setRedirectTo(paths.userTypeSelection);
      }
    }
  }, [user]);

  return (
    <div>
      <NavigationBar />
      <Outlet />
      <SignInModal />
      {redirectTo ? (
        <Navigate
          to={{
            pathname: redirectTo,
            search: createSearchParams(
              postSignUp && { postSignUp: postSignUp },
            ).toString(),
          }}
          replace
        />
      ) : (
        <ViewExpertProfileDialog />
      )}
    </div>
  );
}

function SignInModal() {
  const { isSignUpModalOpen, closeSignUpModal } = useSignUpSearchParams();
  const { user } = useAuth();

  useEffect(() => {
    if (isSignUpModalOpen) {
      handlers.open();
    } else {
      handlers.close();
    }
  }, [isSignUpModalOpen]);

  const [opened, handlers] = useDisclosure(isSignUpModalOpen);

  if (user) {
    return null;
  }

  return (
    <Modal
      zIndex={999}
      classNames={{
        body: "p-10 pt-0",
        content: "lg:max-w-[30rem]",
        header: "p-2 pb-1",
      }}
      opened={opened}
      withCloseButton
      onClose={() => {
        closeSignUpModal();
      }}
    >
      <SignInOptions />
    </Modal>
  );
}
