import { countryList } from "@/utils/country-list";
import * as amplitudeSDK from "@amplitude/analytics-browser";
import { captureException } from "@sentry/react";
import Cookies from "js-cookie";

import type {
  BestPracticeDocument,
  SDocument,
  SoftwareDocument,
  User,
} from "types";

export const Amplitude = {
  ...amplitudeSDK,
  track: (...args: Parameters<(typeof amplitudeSDK)["track"]>) => {
    try {
      return amplitudeSDK.track(...args);
    } catch (error) {
      captureException(error);
    }
  },
};

const apiKey = import.meta.env.VITE_AMPLITUDE_API_KEY;

const trackingConsent: { performance: boolean; functional: boolean } =
  Cookies.withConverter({
    read: (value) => {
      if (!value) return {};

      // If the cookie is malformed, default to consent "on"
      try {
        return JSON.parse(value);
      } catch (_e) {
        return {};
      }
    },
  }).get("userConsent") || {};

if (apiKey) {
  Amplitude.init(apiKey, {
    defaultTracking: true,
    autocapture: {
      pageViews: false,
    },
    // EU users can opt out of tracking using the cookie consent UI on
    // guidestack.com
    optOut: trackingConsent.performance === false,
    logLevel:
      Amplitude.Types.LogLevel[
        import.meta.env
          .VITE_AMPLITUDE_LOG_LEVEL as keyof typeof Amplitude.Types.LogLevel
      ],
  });
}

const identifyEvent = new Amplitude.Identify();
if (import.meta.env.MODE === "staging" || import.meta.env.DEV) {
  identifyEvent.set("env", "guidestack");
  Amplitude.identify(identifyEvent);
}

export function SetUser(user: User, additionalProps?: Record<string, string>) {
  Amplitude.setUserId(user.id);

  const { expertProfile, clientProfile } = user;

  const country = countryList.find((c) => c.code === user.countryCode);

  if (country) {
    identifyEvent.set("country", country.name);
  }

  if (expertProfile) {
    identifyEvent.set("user_type", "Expert");
    identifyEvent.set(
      "approved_expert",
      expertProfile.status === "APPROVED" ? "True" : "False",
    );

    identifyEvent.set(
      "published_document_count",
      expertProfile.publishedDocumentCount ?? 0,
    );

    if (expertProfile.preferClientSizes) {
      identifyEvent.set(
        "expert_company_size_preference",
        expertProfile.preferClientSizes,
      );
    }

    if (expertProfile.preferClientMaturities) {
      identifyEvent.set(
        "expert_IT_maturity_preference",
        expertProfile.preferClientMaturities,
      );
    }
  }

  if (clientProfile) {
    identifyEvent.set("user_type", "Client");

    if (clientProfile.jobTitle) {
      identifyEvent.set("client_job_title", clientProfile.jobTitle);
    }

    if (clientProfile.company?.size) {
      identifyEvent.set("client_company_size", clientProfile.company.size);
    }

    if (clientProfile.company?.workMode) {
      identifyEvent.set("client_work_setup", clientProfile.company.workMode);
    }

    if (clientProfile.itLevel) {
      identifyEvent.set("client_IT_level", clientProfile.itLevel);
    }

    if (clientProfile.company?.productivitySuite) {
      identifyEvent.set(
        "client_productivity_suite",
        clientProfile.company.productivitySuite,
      );
    }
  }

  for (const [key, value] of Object.entries(additionalProps || {})) {
    identifyEvent.set(key, value);
  }

  Amplitude.identify(identifyEvent);
}

export function SetExpertSoftware(softwareList: string[]) {
  identifyEvent.setOnce("expert_software_expertise", softwareList);
  Amplitude.identify(identifyEvent);
}

export function SetExpertIndustries(industryList: string[]) {
  identifyEvent.setOnce("expert_industry_expertise", industryList);
  Amplitude.identify(identifyEvent);
}

export function SetClientSoftware(softwareList: string[]) {
  identifyEvent.setOnce("client_software_used", softwareList);
  Amplitude.identify(identifyEvent);
}

export function SetClientIndustry(industryName: string) {
  identifyEvent.setOnce("client_industry", industryName);
  Amplitude.identify(identifyEvent);
}

export const SignUpAndLoginEvent = {
  name: "signup_and_login",
  properties: {
    selectGoogle: {
      action: "Select Google SSO",
    },
    selectMicrosoft: {
      action: "Select Microsoft SSO",
    },
    selectExpert: {
      action: "Select User Type",
      user_type: "Expert",
    },
    selectClient: {
      action: "Select User Type",
      user_type: "Client",
    },
    completeSignup: {
      action: "Complete Signup",
    },
  },
};

export const ExpertOnboardingEvent = {
  name: "expert_onboarding",
  properties: {
    uploadPhoto: {
      action: "Click Upload Photo",
    },
    profileNext: {
      action: "Profile- Click Next",
    },
    preferenceSubmit: {
      action: "Preferences- Click Submit",
    },
    skipOnboarding: {
      action: "Click Skip Onboarding",
    },
    completeGoSubmit: {
      action: "Success- Click Submit Document",
    },
    completeGoDashboard: {
      action: "Success- Click Go to Dashboard",
    },
  },
};

export const ExpertOwnProfileEvent = {
  name: "expert_own_profile",
  properties: {
    editProfile: {
      action: "Click Edit Profile",
    },
    editSoftware: {
      action: "Click Edit Software",
    },
    addVideo: {
      action: "Add Video",
    },
    changeVideo: {
      action: "Change Video",
    },
    removeVideo: {
      action: "Remove Video",
    },
    editTechnicalAreas: {
      action: "Click Edit Technical Expertise",
    },
    editIndustries: {
      action: "Click Edit Industries",
    },
    editClientPreferences: {
      action: "Click Edit Client Preferences",
    },
    profileModalClose: {
      action: "Profile Modal- Click Close",
    },
    softwareModalClose: {
      action: "Software Modal- Click Close",
    },
    technicalAreasModalClose: {
      action: "Technical Modal- Click Close",
    },
    industriesModalClose: {
      action: "Industries Modal- Click Close",
    },
    preferenceModalClose: {
      action: "Preferences Modal- Click Close",
    },
  },
};

export const ExpertDashboardCalloutsEvent = {
  name: "expert_dashboard_callouts",
  properties: {
    completeProfile: {
      action: "Click Complete Profile",
    },
    addSoftware: {
      action: "Click Add Software",
    },
    addIntroVideo: {
      action: "Click Add Intro Video",
    },
    addTechnicalExpertise: {
      action: "Click Add Technical Expertise",
    },
    addIndustries: {
      action: "Click Add Industries",
    },
    writeDocument: {
      action: "Click Write Document",
    },
    profileModalStart: {
      action: "Profile Modal- Click Start",
    },
    profileModalCancel: {
      action: "Profile Modal- Click Cancel",
    },
    clickBookingSetup: {
      action: "Click Calendar Setup",
    },
    clickPaymentSetup: {
      action: "Click Payment Setup",
    },
  },
};

export const ClientOnboardingEvent = {
  name: "client_onboarding",
  properties: {
    uploadPhoto: {
      action: "Click Upload Photo",
    },
    profileNext: {
      action: "Profile- Click Next",
    },
    companyNext: {
      action: "Company- Click Next",
    },
    itSetupSubmit: {
      action: "IT Setup- Click Submit",
    },
    skipOnboarding: {
      action: "Click Skip Onboarding",
    },
    completeGoDashboard: {
      action: "Success- Click Go to Dashboard",
    },
  },
};

export const ClientOwnProfileEvent = {
  name: "client_own_profile",
  properties: {
    editProfile: {
      action: "Click Edit Profile",
    },
    editSoftware: {
      action: "Click Edit Software",
    },
    editCompanyInfo: {
      action: "Click Edit Company Info",
    },
    editItSetup: {
      action: "Click Edit IT Setup",
    },
    profileModalClose: {
      action: "Profile Modal- Click Close",
    },
    softwareModalClose: {
      action: "Software Modal- Click Close",
    },
    companyModalClose: {
      action: "Company Modal- Click Close",
    },
    itSetupModalClose: {
      action: "IT Setup Modal- Click Close",
    },
  },
};

export function createDocumentProperties(
  doc: SoftwareDocument | BestPracticeDocument,
) {
  return {
    ...buildDocumentProperties(doc),
    ...((doc.docType === "SOFTWARE_CHECKLIST" ||
      (doc.docType === "IT_BEST_PRACTICE" &&
        !doc.relatedTopicDocuments?.length)) && {
      ...buildExpertProperties(doc.author),
      likes_number: doc.likeCount || 0,
    }),
  };
}

export function buildExpertProperties(expert?: SDocument["author"] | User) {
  return {
    expert_user_id: expert?.id || "",
    expert_industry_expertise: expert?.industries || [],
    expert_city: expert?.city || "",
    expert_country:
      countryList.find((c) => c.code === expert?.countryCode)?.name || "",
  };
}

export function buildDocumentProperties(
  doc: SoftwareDocument | BestPracticeDocument,
) {
  return {
    ...(doc.docType === "SOFTWARE_CHECKLIST"
      ? buildSoftwareDocumentProperties(doc)
      : buildBestPracticesDocumentProperties(doc)),
  };
}

function buildSoftwareDocumentProperties(doc: SoftwareDocument) {
  const subPrompts = doc.content?.prompts
    .flatMap((prompt) => prompt.subPrompts)
    .flatMap((sp) => sp?.subPrompts);

  function getSubpromptByTitle(title: string) {
    return subPrompts?.find((sp) => sp?.title === title);
  }

  return {
    document_name: doc.software?.name || "",
    document_type: "Software Guide",
    document_category: doc.software?.category?.category || "",
    document_sub_category: doc.software?.category?.subcategory || "",
    links_added: doc.content?.prompts[0]?.subPrompts
      ?.flatMap((sp) => sp.subPrompts)
      .flatMap((sp) => sp?.metadata?.relevantArticles?.value).length
      ? "True"
      : "False",
    migration_field_complete: getSubpromptByTitle(
      "Migrating from similar tools",
    )?.value
      ? "True"
      : "False",
    compatibility_field_complete: getSubpromptByTitle("Compatible tools")?.value
      ? "True"
      : "False",
    incompatibility_field_complete: getSubpromptByTitle("Less compatible tools")
      ?.value
      ? "True"
      : "False",
  };
}

function buildBestPracticesDocumentProperties(doc: BestPracticeDocument) {
  return {
    document_name: doc.topic?.title || "",
    document_type: "IT Best Practices",
    // Do not send these fields when there are more than one document
    ...(!doc.relatedTopicDocuments?.length && {
      software_added:
        doc.content?.prompts[0]?.subPrompts?.[0]?.metadata?.recommendations?.value.map(
          (v) => v.name,
        ),
      links_added: doc.content?.prompts[0]?.subPrompts?.[0]?.metadata
        ?.relevantArticles?.value.length
        ? "True"
        : "False",
    }),
    document_category: doc.topic?.categories?.join(", ") || "",
  };
}

export function createDocumentSubmissionModalProperties(
  doc: SDocument,
  action: "Click Submit" | "Click Cancel",
  modalType: "ToS Agreement" | "Standard",
) {
  return {
    action,
    modalType,
    prompt_selection:
      doc.content?.prompts?.map((p) => p.title).join(", ") || "",
    ...createDocumentProperties(doc),
  };
}

export function createDocumentInteractionsProperties(
  doc: SDocument,
  isAuthed: boolean,
) {
  return {
    ...buildDocumentProperties(doc),
    user_status: isAuthed ? "Logged In" : "Logged Out",
    // todo:
    // expert_published_document_count: doc.author?.publishedDocumentCount || 0,
    // expert_software_expertise: doc.author?.softwareExpertise || [],
    // comments_number: doc.commentCount || 0, -- comments are in a sibling
    // component
  };
}

export const ExpertOwnDocumentsEvent = {
  name: "expert_own_documents",
  properties: {
    clickDocument: {
      action: "Click Document",
    },
    menuEdit: {
      action: "Menu- Click Edit",
    },
    menuDelete: {
      action: "Menu- Click Delete",
    },
    menuMoveToDraft: {
      action: "Menu- Click Move to Draft",
    },
    draftConfirmMove: {
      action: "Draft Confirmation- Click Move",
    },
    draftConfirmCancel: {
      action: "Draft Confirmation- Click Cancel",
    },
    deleteConfirmDelete: {
      action: "Delete Confirmation- Click Delete",
    },
    deleteConfirmCancel: {
      action: "Delete Confirmation- Click Cancel",
    },
  },
};

export const DocumentCalloutEvent = {
  name: "document_callout",
  properties: {
    clickCreate: {
      action: "Click Create",
    },
  },
};

export const DocumentPromptSelectionEvent = {
  name: "document_prompt_selection",
  properties: {
    clickStart: {
      action: "Click Start Writing",
    },
    clickCancel: {
      action: "Click Cancel",
    },
  },
};

export const DocumentCreationEvent = {
  name: "document_creation",
  properties: {
    clickSubmit: {
      action: "Click Submit",
    },
    clickSave: {
      action: "Click Save",
    },
  },
};

export const DocumentSubmissionModalEvent = {
  name: "document_submission_modal",
  properties: {
    clickSubmit: {
      action: "Click Submit",
    },
    clickCancel: {
      action: "Click Cancel",
    },
  },
};

export const DocumentationDashboardEvent = {
  name: "documentation_dashboard",
  properties: {
    clickDocument: {
      action: "Click Document",
    },
    clickCategory: {
      action: "Click Category",
    },
  },
};

export const DocumentInteractionsEvent = {
  name: "document_interactions",
  properties: {
    viewDocument: {
      action: "View Document",
    },
    likeDocument: {
      action: "Like Document",
    },
    unlikeDocument: {
      action: "Unlike Document",
    },
    openExpertProfile: {
      action: "Open Expert Profile",
    },
    clickLinkPreview: {
      action: "Click Link Preview",
    },
    clickSoftwareTool: {
      action: "Click Software Tool",
    },
  },
};

export const PreSignupCalloutEvent = {
  name: "pre_signup_callout",
  properties: {
    clickSignUp: {
      action: "Click Sign Up",
    },
  },
};

export const NotificationClickEvent = {
  name: "click_notification",
  properties: {
    userLikedDocument: {
      action: "Click Notification",
      notification_type: "User Liked Your Document",
    },
    userCommentedDocument: {
      action: "Click Notification",
      notification_type: "User Commented on Your Document",
    },
    userCommentedSameDocument: {
      action: "Click Notification",
      notification_type: "User Commented on Same Document You Commented On",
    },
  },
};

export const DocumentCommentEvent = {
  name: "document_comments",
  properties: {
    publishComment: {
      action: "Publish Comment",
    },
    menuEdit: {
      action: "Menu- Click Edit",
    },
    menuDelete: {
      action: "Menu- Click Delete",
    },
    confirmDelete: {
      action: "Delete Confirmation- Click Delete",
    },
    confirmCancel: {
      action: "Delete Confirmation- Click Cancel",
    },
  },
};

export const CommentInteractionsEvent = {
  name: "comment_interactions",
  properties: {
    likeComment: {
      action: "Like Comment",
    },
    unlikeComment: {
      action: "Unlike Comment",
    },
    openExpertProfile: {
      action: "Open Expert Profile",
    },
  },
};

export const UserMenuEvent = {
  name: "user_menu",
  properties: {
    clickProfile: {
      action: "Click Profile",
    },
    clickAccount: {
      action: "Click Account",
    },
    clickContact: {
      action: "Click Contact",
    },
    clickSignOut: {
      action: "Click Sign Out",
    },
    clickGuideStackToS: {
      action: "Click GuideStack ToS",
    },
    clickExpertToS: {
      action: "Click Expert ToS",
    },
    clickFLPToS: {
      action: "Click FLP ToS",
    },
    clickPrivacyPolicy: {
      action: "Click Privacy Policy",
    },
    clickCommercialTxnAct: {
      action: "Click Special Commercial Transaction Act",
    },
    logoutDialogClickSignOut: {
      action: "Logout Dialog- Click Sign Out",
    },
    logoutDialogClickCancel: {
      action: "Logout Dialog- Click Cancel",
    },
  },
};

export const ClientDashboardCalloutsEvent = {
  name: "client_dashboard_callouts",
  properties: {
    clickCompleteProfile: {
      action: "Click Complete Profile",
    },
    clickAddSoftware: {
      action: "Click Add Software",
    },
    clickFindExpert: {
      action: "Click Find Expert",
    },
  },
};

export const ExpertProfileEvent = {
  name: "expert_public_profile",
  properties: {
    clickBookIntroSession: {
      action: "Click Book Intro",
    },
    clickVideo: {
      action: "Click Video",
    },
    clickBookAdvisorySession: {
      action: "Click Book Session",
    },
    clickDocument: {
      action: "Click Document",
    },
  },
};

export const PaymentModalsEvent = {
  name: "payment_modals",
  properties: {
    paymentModalAddPayment: {
      action: "Payment Modal- Click Add Payment",
    },
    paymentModalCancel: {
      action: "Payment Modal- Click Cancel",
    },
    paymentModalUseVoucher: {
      action: "Payment Modal- Click Use Voucher",
    },
    unpaidModalPay: {
      action: "Unpaid Modal- Click Pay",
    },
    unpaidModalCancel: {
      action: "Unpaid Modal- Click Cancel",
    },
  },
};

export const ClientAccountEvent = {
  name: "client_account",
  properties: {
    clickAddPayment: {
      action: "Click Add Payment",
    },
    clickManagePayments: {
      action: "Click Manage Payments",
    },
  },
};

export const ExpertAccountAvailabilityEvent = {
  name: "expert_availability",
  properties: {
    clickStart: {
      action: "Click Start",
    },
    emptyStateClickSubmitDocument: {
      action: "Empty State- Click Submit Document",
    },
  },
};

export const ExpertAccountPayoutsEvent = {
  name: "expert_payouts",
  properties: {
    connectPayout: {
      action: "Connect Payout Account",
    },
    viewPayoutAccount: {
      action: "View Payout Account",
    },
    emptyStateClickSubmitDocument: {
      action: "Empty State- Click Submit Document",
    },
  },
};

export const SessionNotesEvent = {
  name: "session_notes",
  properties: {
    clickSubmit: {
      action: "Click Submit",
    },
    clickCancel: {
      action: "Click Cancel",
    },
  },
};

export const SessionReviewEvent = {
  name: "session_review",
  properties: {
    clickSubmit: {
      action: "Click Submit",
    },
    clickCancel: {
      action: "Click Cancel",
    },
  },
};

export const SessionBookedEvent = {
  name: "session_booked",
};

export const SessionsDashboardEvent = {
  name: "sessions_dashboard",
  properties: {
    clickExpertName: {
      action: "Click Expert Name",
    },
    clickClientName: {
      action: "Click Client Name",
    },
    clickMarkComplete: {
      action: "Click Mark Complete",
    },
    clickMarkCanceled: {
      action: "Click Mark Canceled",
    },
    clickReschedule: {
      action: "Click Reschedule",
    },
    clickCancel: {
      action: "Click Cancel",
    },
    clickViewNotes: {
      action: "Click View Notes",
    },
    clickAddReview: {
      action: "Click Add Review",
    },
    clickBookAgain: {
      action: "Click Book Again",
    },
    notesClickAddReview: {
      action: "Notes Modal- Click Add Review",
    },
    notesClickBookAgain: {
      action: "Notes Modal- Click Book Again",
    },
  },
};

export const ExpertDirectoryEvent = {
  name: "expert_directory",
  properties: {
    clickExpertName: {
      action: "Click Expert Name",
    },
  },
};

export const LandingPageCTAEvent = {
  name: "landing_page_CTA",
  properties: {
    clickCTA: {
      action: "Click CTA",
    },
  },
};

export const RedeemVoucherEvent = {
  name: "voucher_code",
  properties: {
    clickRedeem: {
      action: "Code Dialog- Click Redeem",
    },
    clickContinueBooking: {
      action: "Success- Click Continue Booking",
    },
  },
};

export const DocumentSessionCalloutEvent = {
  name: "document_session_callout",
  properties: {
    clickExpertName: {
      action: "Click Expert Name",
    },
    clickExpertLinkedIn: {
      action: "Click Expert LinkedIn",
    },
    clickBook: {
      action: "Click Book",
    },
  },
};
